import { createRouter, createWebHistory } from 'vue-router'
import jwt_decode from 'jwt-decode'

// Auth Default Routes
const authChildRoutes = (prefix) => [
  {
    path: 'login',
    name: prefix + '.login',
    meta: { auth: false, name: 'Login' },
    component: () => import('@/views/auth/default/SignIn.vue')
  }
]

// Default routes
const defaultChildRoutes = (prefix) => [
  {
    path: '',
    name: prefix + '.dashboard',
    meta: { auth: true, name: 'Home', isBanner: false },
    component: () => import('@/views/UserProfile.vue')
  },
  {
    path: '/user-profile',
    name: prefix + '.user-profile',
    meta: { auth: true, name: 'User Profile', isBanner: false },
    component: () => import('@/views/UserProfile.vue')
  },
  {
    path: '/user-list',
    name: prefix + '.user-list',
    meta: { auth: true, name: 'User List', isBanner: false },
    component: () => import('@/views/UserView.vue')
  },
  {
    path: '/account',
    name: prefix + '.account',
    meta: { auth: true, name: 'Account', isBanner: false },
    component: () => import('@/views/AccountView.vue')
  }
]

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('default')
  },

  // Auth Skins
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../layouts/guest/BlankLayout.vue'),
    children: authChildRoutes('auth')
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    meta: { auth: false, name: 'Error 404', isBanner: true },
    component: () => import('@/views/errors/Error404Page.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes
})

const isTokenExpired = (token) => (jwt_decode(token)?.exp ?? 0) * 1000 < Date.now()

// Navigation guard
router.beforeEach((to, from, next) => {
  let user = JSON.parse(localStorage.getItem('user'))
  let token = localStorage.getItem('token')

  // Check if requested route must auth
  if (to.matched.some((record) => record.meta.auth)) {
    // check if user logged in and token is not expired
    if (user && token && !isTokenExpired(token)) {
      // check if route desination to user list and the user is not admin
      if (to.name == 'default.user-list' && user.role !== 'admin') {
        next({ name: 'default.user-profile' })
      } else {
        next()
      }
    } else {
      localStorage.removeItem('user')
      localStorage.removeItem('token')

      next('/auth/login')
    }
  } else {
    next()
  }
})

export default router
