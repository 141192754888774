import axios from 'axios'
import router from '@/router'

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    // If error 401 - unauthorized - redirect to login
    if (error.response.status === 401) {
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      router.push({ name: 'auth.login' })
    }
    return Promise.reject(error)
  }
)
